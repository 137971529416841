import { StaticImage } from "gatsby-plugin-image"
import { useState } from "react"
import { motion } from "framer-motion"

import { MotionDiv, MotionSection } from "../components/animation/elements"
import { FadeIn, FadeOut } from "../components/animation"
import {
  fadeInDown,
  fadeInRight,
  fadeInUp,
  fadeOut,
} from "../components/animation/fade"
import CTA from "../components/CTA"
import Header from "../components/header"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import { Marker } from "../utils"
import AboutPic2 from "../images/about-pic-3.jpg"
const headerProps = {
  title: "About",
  caption:
    "A passionate bunch of people who love creating products that are eco-friendly & functional",
}

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Header {...headerProps} />
    <IntroSection />
    {/* <ServicesSection /> */}
    <CTA />
  </Layout>
)
const IntroSection = () => (
  <Container>
    <div className="-mx-4 flex flex-wrap">
      <div className="w-full px-4">
        <div className="flex flex-col-reverse justify-between lg:flex-row">
          <FadeIn
            tag="div"
            className="w-full lg:pr-16 lg:max-w-[565px] xl:max-w-[640px]"
          >
            <MotionDiv variant={fadeInUp}>
              <span className="sectionHeading mb-4 text-green-800">
                we are professionals
              </span>
              <h2 className="subheading mb-6 md:mb-10">
                Top <Marker>quality prints</Marker>
                <br />
                using the latest technology
              </h2>
              <div className="pageText">
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint
                  voluptate rerum dolorem fugiat repellendus magni ea eveniet
                  eos minima incidunt exercitationem expedita veritatis
                  temporibus, error tenetur. Veniam atque neque maiores quaerat
                  incidunt unde, facilis debitis id nobis excepturi! Vel odit
                  unde ipsam, aliquam deleniti ratione eos accusantium
                  laudantium cumque sint.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                  ducimus eaque soluta nulla doloremque deserunt natus voluptate
                  amet officia accusamus asperiores, eligendi sed voluptatem
                  molestiae repudiandae quae dicta doloribus ab?
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                  ducimus eaque soluta nulla doloremque deserunt natus voluptate
                  amet officia accusamus asperiores, eligendi sed voluptatem
                  molestiae repudiandae quae dicta doloribus ab?
                </p>
              </div>
            </MotionDiv>
          </FadeIn>
          <div className="h-64 w-full sm:h-96 lg:h-full relative mb-8 lg:mb-0">
            <FadeIn tag="div" className="h-full">
              <MotionDiv className="h-full" variant={fadeInRight}>
                <StaticImage
                  src="../images/about-pic-1.jpg"
                  alt="image"
                  className="h-full w-full max-h-[550px] object-cover rounded-lg md:max-w-2xl lg:max-w-lg"
                />
              </MotionDiv>
            </FadeIn>
            <FadeIn tag="div">
              <MotionDiv className="h-full" variant={fadeInDown}>
                {/* <StaticImage
                  src="../images/about-pic-3.jpg"
                  alt="image"
                  className="w-56 h-72 object-cover absolute bottom-0 right-0 bg-black rounded-lg hidden sm:block translate-y-24"
                /> */}
                <img
                  src={AboutPic2}
                  alt="image"
                  className="w-56 h-72 object-cover absolute bottom-0 right-0 bg-black rounded-lg hidden md:block translate-y-24"
                />
              </MotionDiv>
            </FadeIn>
          </div>
        </div>
      </div>
    </div>
  </Container>
)

const Accordion = ({ title, children }) => {
  const [accordionState, setaccordionState] = useState(false)

  return (
    <MotionDiv
      variant={fadeInUp}
      className="single-faq mb-4 md:mb-8 w-full rounded-lg border border-[#F3F4FE] bg-white p-5 sm:p-8"
    >
      <button
        className="faq-btn flex w-full items-center text-left"
        onClick={() => setaccordionState(!accordionState)}
      >
        <motion.div
          initial={{ rotate: 0 }}
          animate={
            accordionState
              ? { rotate: 180, transition: { duration: 0.35 } }
              : { rotate: 0, transition: { duration: 0.35 } }
          }
          className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-secondary bg-opacity-5 text-secondary"
        >
          <svg
            width="17"
            height="10"
            viewBox="0 0 17 10"
            className={`icon fill-current`}
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill="#3056D3"
              stroke="#3056D3"
            />
          </svg>
        </motion.div>
        <div className="w-full">
          <h4 className="text-base font-ms tracking-tight font-semibold text-black sm:text-lg">
            {title}
          </h4>
        </div>
      </button>
      <MotionDiv
        className={`faq-content pl-[62px]`}
        initial={{ display: "none", opacity: 0 }}
        animate={
          accordionState
            ? {
                transitionEnd: {
                  display: "block",
                },
                opacity: 1,
              }
            : {
                transitionEnd: {
                  display: "none",
                },
                opacity: 0,
              }
        }
      >
        <p className="py-3 text-base leading-relaxed text-gray-600">
          {children}
        </p>
      </MotionDiv>
    </MotionDiv>
  )
}

const ServicesSection = () => {
  return (
    <Container className="bg-blue-100">
      <div className="-mx-4 flex flex-wrap">
        <FadeIn tag="div" className="w-full px-4">
          <MotionDiv
            variant={fadeInUp}
            className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20"
          >
            <span className="sectionHeading mb-2 block font-rw text-lg font-bold uppercase">
              Services
            </span>
            <h2 className="subheading mb-4">
              Besides paper bags <br />
              you can <Marker>rely on us</Marker> for these services
            </h2>
          </MotionDiv>
        </FadeIn>
      </div>

      <div className="-mx-4 flex flex-wrap font-lf">
        <FadeIn tag="div" className="w-full px-4 lg:w-1/2">
          <Accordion title="How long we have been in business?">
            It takes 2-3 weeks to get your first blog post ready. That includes
            the in-depth research & creation of your monthly content marketing
            strategy that we do before writing your first blog post, Ipsum
            available .
          </Accordion>
          <Accordion title="How long we have been in business?">
            It takes 2-3 weeks to get your first blog post ready. That includes
            the in-depth research & creation of your monthly content marketing
            strategy that we do before writing your first blog post, Ipsum
            available .
          </Accordion>
          <Accordion title="How long we have been in business?">
            It takes 2-3 weeks to get your first blog post ready. That includes
            the in-depth research & creation of your monthly content marketing
            strategy that we do before writing your first blog post, Ipsum
            available .
          </Accordion>
        </FadeIn>
        <FadeIn tag="div" className="w-full px-4 lg:w-1/2">
          <Accordion title="How long we have been in business?">
            It takes 2-3 weeks to get your first blog post ready. That includes
            the in-depth research & creation of your monthly content marketing
            strategy that we do before writing your first blog post, Ipsum
            available .
          </Accordion>
          <Accordion title="How long we have been in business?">
            It takes 2-3 weeks to get your first blog post ready. That includes
            the in-depth research & creation of your monthly content marketing
            strategy that we do before writing your first blog post, Ipsum
            available .
          </Accordion>
          <Accordion title="How long we have been in business?">
            It takes 2-3 weeks to get your first blog post ready. That includes
            the in-depth research & creation of your monthly content marketing
            strategy that we do before writing your first blog post, Ipsum
            available .
          </Accordion>
        </FadeIn>
      </div>
    </Container>
  )
}

export default AboutPage
