import { motion } from "framer-motion"

export const FadeInUp = ({ className, children }) => {
  const initialState = {
    opacity: 0,
    y: 50,
  }
  const animateState = {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
      ease: "easeIn",
    },
  }
  return (
    <motion.div
      className={className}
      initial={initialState}
      animate={animateState}
    >
      {children}
    </motion.div>
  )
}

export const Marker = ({
  color = "yellow",
  rotate = "clockwise",
  children,
}) => {
  const shades = {
    yellow: "bg-amber-300",
    gray: "bg-slate-200",
  }
  const degree = {
    clockwise: "-rotate-2",
    anticlockwise: "rotate-1",
  }
  return (
    <span className="relative inline-flex">
      <span className="absolute w-full h-full -z-10">
        <span className="absolute w-full h-full -z-10 left-0 top-0">
          <div
            className={`w-full h-full ${shades[color]} ${degree[rotate]}`}
          ></div>
        </span>
      </span>
      <div>{children}</div>
    </span>
  )
}
